import React from 'react';
import Alert from 'react-bootstrap/Alert';

import ContentListComponent from './components/ContentListComponent';
import {ContentService, IContentListEntry} from './services/ContentService';
import Login from './components/Login';

import '@iframe-resizer/child';

import {
	BrowserRouter as Router,
	Switch,
	Route,
} from 'react-router-dom';

import './App.css';
import {Col, Row} from 'react-bootstrap';
import ViewerComponent from './components/ViewerComponent';
import EditorComponent from './components/EditorComponent';
import NewContentHandler from './components/NewContentHandler';

export default class App extends React.Component {
	constructor(props: any) {
		super(props);
		this.contentService = new ContentService(process.env.REACT_APP_API_URL+'/h5p');
	}

	private contentService: ContentService;

	public state: {
		loggedIn: boolean;
		contentList: IContentListEntry[];
	} = {
			loggedIn: false,
			contentList: [],
		};

	public async componentDidMount(): Promise<void> {
		console.log(`
		### DEBUGGER ###
		env: ${process.env.REACT_APP_API_URL}
		state: ${JSON.stringify(this.state)}
		`);
		// Force login as admin
		await this.login('admin');
		await this.updateList();
	}

	render() {
		return (
			<div className="App">
				<Router>
					<Switch>
						<Route path="/ac">
							<Row>
								<Col md="auto" className="m-2">
									<Login
										contentService={this.contentService}
										onLoggedIn={() => {
											this.setState({loggedIn: true});
										}}
										onLoggedOut={() => {
											this.setState({loggedIn: false});
										}}
									/>
								</Col>
							</Row>
							<Alert variant="warning">This demo is for debugging and demonstration purposes only and not suitable for production use!
							</Alert>
							{this.state.loggedIn
								? (
									<ContentListComponent
										contentService={this.contentService}
									/>
								)
								: (
									<Alert variant="danger">
                                        Content is only visible to logged in users! Please
                                        log in with the button on the top
									</Alert>
								)}
						</Route>
						<Route
							path="/viewer/:id"
						>
							{this.state.loggedIn &&
								<ViewerComponent contentService={this.contentService} />
							}
						</Route>
						<Route
							path="/editor/:id/:new?"
						>
							{this.state.loggedIn &&
								<EditorComponent contentService={this.contentService} />
							}
						</Route>
						<Route path="/new/:type">
							<NewContentHandler contentService={this.contentService}/>
						</Route>
						<Route exact path="/">
                            ROUTE: home
						</Route>
						<Route path="*">
                            ROUTE: Catch all
						</Route>
					</Switch>
				</Router>
			</div>
		);
	}

	protected async updateList(): Promise<void> {
		const contentList = await this.contentService.list();
		this.setState({...this.state, contentList: contentList});
	}

	private login = async (username) => {
		await fetch(`${process.env.REACT_APP_API_URL}/login`, {
			method: 'POST',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-type': 'application/json',
			},
			body: JSON.stringify({
				username: username,
				password: username,
			}),
		})
			.then(async (res) => {
				if (res.status === 200) {
					const loginData = await res.json();
					this.setState({
						...this.state,
						loginData,
						loginMessage: undefined,
					});
					if (loginData.csrfToken) {
						this.contentService.setCsrfToken(
							loginData.csrfToken,
						);
					}
					this.setState({loggedIn: true});
				} else {
					this.setState({
						...this.state,
						loginData: undefined,
						loginMessage: await res.text(),
					});
					this.contentService.setCsrfToken(undefined);
					this.setState({loggedIn: false});
				}
			})
			.catch((reason) => {
				this.setState({
					...this.state,
					loginData: undefined,
					loginMessage: reason,
				});
				this.contentService.setCsrfToken(undefined);
				this.setState({loggedIn: false});
			});
	};
}
