import React from 'react';

// The .js references are necessary for requireJs to work in the browser.
import { IContentService, IContentListEntry } from '../services/ContentService';

import {withRouter} from 'react-router-dom';

class NewContentHandler extends React.Component<{
	contentService: IContentService;
	match: any;
	history: any;
	location:any
}> {
	constructor(props: {
		contentService: IContentService,
		match: any,
		history: any,
		location:any
	}) {
		super(props);

		this.state = { contentList: [], newContent: null };
		this.contentService = props.contentService;
	}

	public state: {
		contentList: IContentListEntry[];
		newContent: IContentListEntry | null;
	};

	protected contentService: IContentService;
	/**
	 * Keeps track of newly created content to assign a key
	 * @memberof ContentList
	 */
	protected newCounter = 0;

	protected dataType?: 'presentation' | 'video' | 'blanks' | 'quiz' | 'questionnaire' | 'game' = 'presentation';

	public async componentDidMount(): Promise<void> {
		this.dataType = this.props.match.params.type;
		const searchParams = new URLSearchParams(this.props.location.search);
		const title = searchParams.get('title');
		localStorage.setItem('title', title ?? '');

		await this.updateList();

		await this.new(this.dataType);
	}

	public render(): React.ReactNode {
		return (
			<>
				Redirecting...
			</>
		);
	}

	protected async updateList(): Promise<void> {
		const contentList = await this.contentService.list();
		this.setState({ contentList });
	}

	protected async new(type) {
		let newContent = {
			contentId: 'new',
			mainLibrary: undefined,
			title: 'New H5P',
			originalNewKey: `new-${this.newCounter++}`,
		};

		if (type) {
			const content = await this.contentService.create(type);
			newContent = {
				contentId: content.contentId,
				mainLibrary: undefined,
				title: 'New H5P',
				originalNewKey: `new-${this.newCounter++}`,
			};
		}

		this.setState({
			newContent,
		});

		setTimeout(() => {

			this.props.history.push({
				pathname: `/editor/${newContent.contentId}/true`,
				// state: {} // Data being passed to the new route
			});
		}, 300);
	}
}

export default withRouter(NewContentHandler);
