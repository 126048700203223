import React from 'react';

import {H5PEditorUI} from '@lumieducation/h5p-react';

import {IContentListEntry, IContentService} from '../services/ContentService';
import './ContentListEntryComponent.css';

import {withRouter} from 'react-router-dom';

class EditorComponent extends React.Component<{
	contentService: IContentService;
	match: any;
}> {

	constructor(props: {
		contentService: IContentService;
		match: any;
	}) {
		super(props);
		this.state = {
			loading: true,
			readOnlyState: true,

			saving: false,
			saved: false,
			saveErrorMessage: '',
			saveError: false,
		};
		this.h5pEditor = React.createRef();
		this.saveButton = React.createRef();
		this.handleMessage = this.handleMessage.bind(this);
	}

	public state: {
		asUserId?: string;
		contextId?: string;
		loading: boolean;
		readOnlyState: boolean;

		saving: boolean;
		saved: boolean;
		saveError: boolean;
		saveErrorMessage: string;
	};

	private readonly h5pEditor: React.RefObject<H5PEditorUI>;
	private saveButton: React.RefObject<HTMLButtonElement>;

	protected data: IContentListEntry = {
		contentId: '',
		mainLibrary: '',
		title: '',
	};

	protected newContent: boolean = false;


	componentDidMount() {
		this.data.contentId = this.props.match.params.id;
		this.newContent = this.props.match.params.new === 'true';

		if (this.newContent) {
			setTimeout(() => {
				if (this.state.loading) {
					const currentUrl = window.location.href;
					const urlParts = currentUrl.split('/');
					// Assuming the optional parameter is always at the end
					if (urlParts[urlParts.length - 1] === 'true' || urlParts[urlParts.length - 1] === 'false') {
						urlParts.pop();
					}
					// eslint-disable-next-line no-restricted-globals
					parent.postMessage(`{"type": "h5p", "id": "${this.data.contentId}"}`, '*');
					console.log('Redirecting to (via loading true):', urlParts.join('/'));
					window.location.href = urlParts.join('/');
				} else {
					// TODO: Remove redundant code (needs better debugging)
					const currentUrl = window.location.href;
					const urlParts = currentUrl.split('/');
					// Assuming the optional parameter is always at the end
					if (urlParts[urlParts.length - 1] === 'true' || urlParts[urlParts.length - 1] === 'false') {
						urlParts.pop();
					}
					// eslint-disable-next-line no-restricted-globals
					parent.postMessage(`{"type": "h5p", "id": "${this.data.contentId}"}`, '*');
					console.log('Redirecting to (via loading false):', urlParts.join('/'));
					window.location.href = urlParts.join('/');
				}
			}, 1200);
		}

		//listener for save functionality
		window.addEventListener('message', this.handleMessage);
	}

	public async handleMessage (event) {
		console.log('message received to h5p layer ->', event.data);
		let data = JSON.parse(event.data);
		if(data.type === 'save') {
			// @ts-expect-error unknown function (h5p-customization/main.js)
			setH5PTitle(data.title);
			await this.save();
		}
	}


	public render(): React.ReactNode {

		return (
			<div className={this.state.loading ? 'loading' : ''}>
				<H5PEditorUI
					ref={this.h5pEditor}
					contentId={this.data.contentId}
					loadContentCallback={this.props.contentService.getEdit}
					saveContentCallback={this.props.contentService.save}
					onSaved={this.onSaved}
					onLoaded={this.onEditorLoaded}
					onSaveError={this.onSaveError}
				/>
				<div
					style={{
						visibility: this.state.loading
							? 'visible'
							: 'collapse'
					}}
					className="spinner-border spinner-border-sm m-2"
					role="status"
				></div>
			</div>
		);
	}

	protected async save() {
		this.setState({ saving: true });
		try {
			const returnData = await this.h5pEditor.current?.save();
			if (returnData) {
				console.log('content id ==>', returnData.contentId);
				// eslint-disable-next-line no-restricted-globals
				parent.postMessage({ status: 'completed', title: returnData.metadata.mainLibrary }, '*');
			}
		} catch (error) {
			console.log('error =>', error);
			// We ignore the error, as we subscribe to the 'save-error' and
			// 'validation-error' events.
		}
	}

	protected onSaveError = async (event) => {
		this.setState({
			saving: false,
			saved: false,
			saveError: true,
			saveErrorMessage: 'event.detail.message',
		});
		setTimeout(() => {
			this.setState({
				saveError: false,
			});
		}, 5000);
	};

	protected onSaved = async (event) => {
		this.setState({
			saving: false,
			saved: true,
		});
		setTimeout(() => {
			this.setState({ saved: false });
		}, 3000);
	};

	protected onEditorLoaded = () => {
		this.setState({ loading: false });
	};
}

export default withRouter(EditorComponent);
