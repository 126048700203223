import React from 'react';

import {H5PPlayerUI} from '@lumieducation/h5p-react';

import {IContentListEntry, IContentService} from '../services/ContentService';
import './ContentListEntryComponent.css';

import {withRouter} from 'react-router-dom';


class ViewerComponent extends React.Component<{
	contentService: IContentService;
	match: any;
}> {

	constructor(props: {
		contentService: IContentService;
		match: any;
	}) {
		super(props);
		this.state = {
			loading: true,
			readOnlyState: true
		};
		this.h5pPlayer = React.createRef();
	}

	public state: {
		asUserId?: string;
		contextId?: string;
		loading: boolean;
		readOnlyState: boolean;
	};

	private readonly h5pPlayer: React.RefObject<H5PPlayerUI>;

	protected data: IContentListEntry = {
		contentId: '',
		mainLibrary: '',
		title: '',
	};

	componentDidMount() {
		this.data.contentId = this.props.match.params.id;
	}

	public render(): React.ReactNode {
		return (
			<div className={this.state.loading ? 'loading' : ''}>
				<H5PPlayerUI
					ref={this.h5pPlayer}
					contentId={this.data.contentId}
					contextId={this.state.contextId || undefined}
					asUserId={this.state.asUserId || undefined}
					readOnlyState={this.state.readOnlyState}
					loadContentCallback={
						this.props.contentService.getPlay
					}
					onInitialized={this.onPlayerInitialized}
					onxAPIStatement={(
						statement: any,
						context: any,
						event
					) => console.log(statement, context, event)}
				/>
				<div
					style={{
						visibility: this.state.loading
							? 'visible'
							: 'collapse'
					}}
					className="spinner-border spinner-border-sm m-2"
					role="status"
				></div>
			</div>
		);
	}

	private onPlayerInitialized = () => {
		this.setState({ loading: false });
	};
}

export default withRouter(ViewerComponent);
