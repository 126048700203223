import React from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

// The .js references are necessary for requireJs to work in the browser.
import { IContentService, IContentListEntry } from '../services/ContentService';
import ContentListEntryComponent from './ContentListEntryComponent';

export default class ContentList extends React.Component<{
	contentService: IContentService;
}> {
	constructor(props: { contentService: IContentService }) {
		super(props);

		this.state = { contentList: [], newContent: null };
		this.contentService = props.contentService;
	}

	public state: {
		contentList: IContentListEntry[];
		newContent: IContentListEntry | null;
	};

	protected contentService: IContentService;
	/**
   * Keeps track of newly created content to assign a key
   * @memberof ContentList
   */
	protected newCounter = 0;

	public async componentDidMount(): Promise<void> {
		await this.updateList();
	}

	public render(): React.ReactNode {
		return (
			<div>
				<Button
					variant="primary"
					onClick={() => this.new(false)}
					className="my-2"
				>
					<FontAwesomeIcon icon={faPlusCircle} className="me-2" />
          Create new
				</Button>
				<Button
					variant="primary"
					onClick={() => this.new('blanks')}
					className="my-2"
				></Button>
				<Button
					variant="primary"
					onClick={() => this.new('video')}
					className="my-2"
				>
					<FontAwesomeIcon icon={faPlusCircle} className="me-2" />
          Create new interactive video
				</Button>
				<Button
					variant="primary"
					onClick={() => this.new('blanks')}
					className="my-2"
				>
					<FontAwesomeIcon icon={faPlusCircle} className="me-2" />
          Create fill in the blanks
				</Button>
				<Button
					variant="primary"
					onClick={() => this.new('presentation')}
					className="my-2"
				>
					<FontAwesomeIcon icon={faPlusCircle} className="me-2" />
          Create new presentation
				</Button>
				<Button
					variant="primary"
					onClick={() => this.new('quiz')}
					className="my-2"
				>
					<FontAwesomeIcon icon={faPlusCircle} className="me-2" />
          Create new quiz
				</Button>
				<Button
					variant="primary"
					onClick={() => this.new('questionnaire')}
					className="my-2"
				>
					<FontAwesomeIcon icon={faPlusCircle} className="me-2" />
          Create new questionnaire
				</Button>
				<ListGroup>
					{this.state.newContent && (
						<ContentListEntryComponent
							contentService={this.contentService}
							data={this.state.newContent}
							key={
								this.state.newContent.originalNewKey ??
                this.state.newContent.contentId
							}
							onDiscard={() => this.onDiscard(this.state.newContent)}
							onDelete={() => this.onDelete(this.state.newContent)}
							onSaved={(newData) =>
								this.onSaved(this.state.newContent, newData)
							}
							generateDownloadLink={this.contentService.generateDownloadLink}
						></ContentListEntryComponent>
					)}
				</ListGroup>
			</div>
		);
	}

	protected async updateList(): Promise<void> {
		const contentList = await this.contentService.list();
		this.setState({ contentList });
	}

	protected async new(type) {
		let newContent = {
			contentId: 'new',
			mainLibrary: undefined,
			title: 'New H5P',
			originalNewKey: `new-${this.newCounter++}`,
		};

		if (type) {
			const content = await this.contentService.create(type);
			newContent = {
				contentId: content.contentId,
				mainLibrary: undefined,
				title: 'New H5P',
				originalNewKey: `new-${this.newCounter++}`,
			};
		} 


		this.setState({
			newContent,
		});
	}

	protected onDiscard(content) {
		this.setState({
			contentList: this.state.contentList.filter((c) => c !== content),
		});
	}

	protected async onDelete(content: IContentListEntry | null) {
		if (content) {
			if (!content.contentId) {
				return;
			}
			try {
				await this.contentService.delete(content.contentId);
				this.setState({
					contentList: this.state.contentList.filter((c) => c !== content),
				});
			} catch (error) {
				if (error instanceof Error) {
					console.error(error.message);
				}
			}
		}
	}

	protected async onSaved(
		oldData: IContentListEntry | null,
		newData: IContentListEntry | null
	) {
		this.setState({
			contentList: this.state.contentList.map((c) =>
				c === oldData ? newData : c
			),
		});
	}
}
